import { store } from '../../../redux/store'
import { updateUserData, updateUserFetchStatus, wipeUserData } from '../../../redux/actions/UserActions';
import * as config from '../../../config';
import { createFetch } from "../../fetch/FetchRequest";
import { getCookie, setCookie } from "../../common";
import Router from 'next/router'
import { dispatchNotification } from "../../../layouts/app/components/notificationSystem/notifications";
import { NAV_ACCOUNT_COMPLETE, NAV_DASHBOARD, NAV_EMAIL_UNCONFIRMED, NAV_SIGN_IN } from "../../../config";

function getSupportedStacks(user) {

    let supportedStacks = {};

    if ('periodically' in user.plan['supported_stacks']) {
        supportedStacks = {
            monthly: user.plan['supported_stacks']['periodically'],
            annually: user.plan['supported_stacks']['periodically'],
        }
    } else {
        supportedStacks = user.plan['supported_stacks'];
    }

    user.plan.supportedStacks = supportedStacks;
    delete user.plan['supported_stacks'];
}

function isPageExcluded() {
    let exclude = ['unconfirmed-email'];
    return exclude.some(url => Router.asPath.includes(url))
}

export function getUserData(onSuccess, onError) {

    if (isPageExcluded()) return;

    let withAuth = {'Authorization': 'Bearer ' + getCookie(config.USER_ACCESS_TOKEN_NAME)};
    let hash = localStorage.getItem('hash');
    let requestTitle = 'User data';
    let URL = config.URL_ME;
    // show components that something will happen to user data
    store.dispatch(updateUserFetchStatus('loading'));

    createFetch({
        URL: URL,
        requestTitle: requestTitle,
        headers: withAuth,
        onSuccess: userData => {

            let {user} = userData;
            let fetchStatus = 'success';
            let userSuspended = user['delete_days_left'] && !!parseFloat(user['delete_days_left']);
            // redirect user if account is suspended
            if (userSuspended && !location.pathname.includes(config.NAV_ACCOUNT_RESTORE)) {
                dispatchNotification({
                    requestTitle: requestTitle,
                    message: 'Your account is suspended. Restore it to use try.direct',
                    type: 'warning',
                    url: URL
                });
                Router.replace(config.NAV_ACCOUNT_RESTORE);
            }
            // redirect user if email is absent
            if (user.hasOwnProperty('email') && !user.email && !location.pathname.includes(config.NAV_ACCOUNT_COMPLETE)) {
                dispatchNotification({
                    requestTitle: requestTitle,
                    message: 'Please complete your account registration',
                    type: 'warning',
                    url: URL
                });
                Router.replace(`${NAV_ACCOUNT_COMPLETE}/${hash}`);
                return
            }

            if (user['_id']) setCookie('QID', user['_id']);
            // get what stacks user is allowed to deploy
            getSupportedStacks(user);
            // reset suspend status if account was restored - pricing page will fail without this
            user = {
                ...user,
                ...{
                    delete_days_left: (
                        user['delete_days_left'] === undefined || user['delete_days_left'] === null || !parseFloat(user['delete_days_left'])
                    ) ? undefined : user['delete_days_left']
                }
            };

            store.dispatch(updateUserData(user));
            store.dispatch(updateUserFetchStatus(fetchStatus));

            if (onSuccess) onSuccess(userData);
        },
        onError: data => {

            let notificationConfig = {
                message: null,
                requestTitle: requestTitle,
                url: URL,
                status: data,
                type: 'error'
            };

            switch (true) {
                case data.error.status === 400 && !!data.responseBody._error.message.email :
                    notificationConfig.message = "This email address is already in use. Please sign in using your existing account.";
                    notificationConfig.type = 'warning';
                    notificationConfig.status = data.error.status;
                    location.replace(`${NAV_EMAIL_UNCONFIRMED}?text=${notificationConfig.message}`);
                    break;
                case data.error.status === 400 && data.responseBody._error.message === "E-mail is not confirmed, please confirm e-mail first" :
                    notificationConfig.message = 'You should confirm your email first';
                    notificationConfig.type = 'warning';
                    notificationConfig.status = data.error.status;
                    if (!Router.asPath.includes(config.NAV_EMAIL_UNCONFIRMED)) {
                        Router.replace(`${NAV_EMAIL_UNCONFIRMED}?text=${data.responseBody._error.message}`);
                    }
                    break;
                case data.error.status === 400 :
                    notificationConfig.message = data.responseBody._error.message;
                    notificationConfig.status = data.error.status;
                    break;
                case data.error.status === 401 : // when expired token
                    notificationConfig.message = 'Sign in is required';
                    notificationConfig.type = 'warning';
                    notificationConfig.status = data.error.status;
                    saveLocationForRedirect();
                    onAuthError();
                    break;
                default:
                    notificationConfig.message = 'Something went wrong';
                    notificationConfig.type = 'error';
                    notificationConfig.status = data.error.status;
            }

            dispatchNotification(notificationConfig);
            if (onError) onError(data);
        }
    });
}

export function saveLocationForRedirect() {
    if (Router.pathname === NAV_SIGN_IN) return;
    const lastLocation = {'url': Router.asPath}; // asPath saves query params after authorisation
    window.localStorage.setItem(config.LAST_STATE_STORAGE_NAME, JSON.stringify(lastLocation));
}

export function redirectToLastState() {

    const lastLocationData = JSON.parse(localStorage.getItem(config.LAST_STATE_STORAGE_NAME) || '{}')?.url;
    let authRedirectLocation = NAV_DASHBOARD;

    if (!!lastLocationData && lastLocationData !== NAV_SIGN_IN) authRedirectLocation = lastLocationData;

    Router.replace(authRedirectLocation);
    localStorage.setItem(config.LAST_STATE_STORAGE_NAME, '');
}

export function onAuthError() { // this func doesn't clear localStorage and QID to avoid bugs with redirect
    setCookie('session', "");
    setCookie(config.USER_ACCESS_TOKEN_NAME, "");
    store.dispatch(wipeUserData());
    if (Router.pathname !== NAV_SIGN_IN) Router.push(NAV_SIGN_IN);
}

export function authNotify(message, type) {
    dispatchNotification({
        message: message || 'Sign in is required to continue',
        type: type || 'warning',
        status: 401,
    });
}