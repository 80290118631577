import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import styles from './LayoutFull.module.scss'
import ScrollTopButton from "./components/ScrollTopButton";
import Router, { withRouter } from "next/router";
import { getCookie, setCookie } from "../../helpers/common";
import * as config from "../../config";
import { authNotify, getUserData, saveLocationForRedirect } from "../../helpers/data/get/user";
import { NAV_SIGN_IN } from "../../config";
import { updateUserPlan } from "../../redux/actions/UserActions";
import { getCurrentPlan } from "../../helpers/data/_handlers/plans";

class LayoutFull extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMounted: false,
        }
    }

    componentDidMount() {
        this.setState({isMounted: true});
    }

    doAuthRelatedStuff = () => {
        // saving token while pass authorization on socials
        // do not delete !
        // removing this code will cause fail authorization via socials
        let token = getCookie(config.USER_ACCESS_TOKEN_NAME);
        if (token) setCookie(config.USER_ACCESS_TOKEN_NAME, token);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        let {isMounted} = this.state;
        let {user, plans, userFetchStatus, currentPlan, selectedPlan, auth} = this.props;

        if (isMounted !== prevState.isMounted && !!isMounted) {
            if (!!getCookie(config.USER_ACCESS_TOKEN_NAME) && (!!auth || !user) && !this.props.router.pathname.includes(config.NAV_ACCOUNT_COMPLETE)) {
                getUserData();
            } else if (!!auth && !getCookie(config.USER_ACCESS_TOKEN_NAME) && Router.pathname !== NAV_SIGN_IN) {
                authNotify();
                saveLocationForRedirect();
                Router.replace(NAV_SIGN_IN);
            }

            this.doAuthRelatedStuff();
        }

        if (
            (
                isMounted !== prevState.isMounted
                || plans !== prevProps.plans
                || userFetchStatus !== prevProps.userFetchStatus
            )
            && !currentPlan && !selectedPlan
            && !!plans && !!plans.length
            && userFetchStatus === 'success' && !!user.plan && !!user.plan.code
        ) {
            this.props.dispatch(updateUserPlan(getCurrentPlan(plans, user)));
        }
    }

    render() {


        let {children, scrollTopButton = {}} = this.props;

        return (
            <div className={styles['layout-full']}>
                <main className={styles['layout-full__container']}>
                    {children}
                </main>
                <ScrollTopButton hide={scrollTopButton.hide} lift={scrollTopButton.lift}/>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    user: state.userData.user,
    userFetchStatus: state.userData.userFetchStatus,
    plans: state.commonData.plans,
    currentPlan: state.userData.currentPlan,
    // selectedPlan: state.payment.selectedPlan,
});

export default connect(mapStateToProps)(withRouter(LayoutFull))

LayoutFull.propTypes = {
    auth: PropTypes.bool,
    deployment: PropTypes.bool,
    scrollTopButton: PropTypes.shape({
        hide: PropTypes.bool || undefined,
        lift: PropTypes.bool || undefined,
    }),
}