import * as config from '../../config';
// import { dispatchNotification } from '../../commonHelpers/dataHelpers/notifications';

// const config = {
//     DEBUG: true,
// }

const checkArgumentsTypes = fetchConfig => {

    const requiredTypes = {
        method: ['string'],
        URL: ['string'],
        onSuccess: ['function'],
        onError: ['function'],
        headers: ['object'],
        body: ['object', 'string'],
        requestTitle: ['string']
    };

    Object.keys(fetchConfig).map(property => {
        if (
            Object.keys(requiredTypes).includes(property)
            && !requiredTypes[property].includes(typeof fetchConfig[property])
        ) {
            console.group(`Possible error during Fetch request (${fetchConfig.URL})`);
            console.warn(
                `Type of property "${property}" is invalid : `
                + `expected "${requiredTypes[property]}", but received "${typeof fetchConfig[property]}".`
            );
            console.groupEnd();
        }

        if (!Object.keys(requiredTypes).includes(property)) {
            console.group(`Warning during Fetch request (${fetchConfig.URL})`);
            console.warn(`Unexpected property "${property}". It will be ignored.`);
            console.groupEnd();
        }
    })
};

export function createFetch({
                                method: method = 'GET',
                                URL: URL,
                                requestTitle: requestTitle = null,
                                headers: headers,
                                onSuccess: onSuccess,
                                onError: onError,
                                body: body = null
                            }) {

    let requestConfig = {
        method: method,
        // headers: headers ? headers : {'Content-Type': 'application/json'}
        headers: headers ? headers : undefined,
    };

    if (body && typeof body === 'object') {
        requestConfig.body = JSON.stringify(body);
    } else if (body && typeof body !== 'object') {
        requestConfig.body = body;
    }

    if (config.DEBUG && URL) checkArgumentsTypes(arguments[0]);

    fetch(URL, requestConfig)
        .then(response => {
            if (!response.ok) throw response;
            if (response.status === 204) return null;
            return response.json()
        })
        .then(data => {
            try {
                if (onSuccess) onSuccess(data);
            } catch (e) {
                if (config.DEBUG) console.error(e)
            }
        })
        .catch(error => {

            let responseContent = null;
            new Promise(resolve => {
                try {
                    error.text().then(text => {
                        try {
                            resolve(responseContent = JSON.parse(text))
                        } catch (err) {
                            resolve(responseContent = text)
                        }
                    })
                } catch (err) {
                    resolve(null)
                }
            }).then(responseContent => {
                let handlerError = {
                    ...{error: error},
                    ...{responseBody: responseContent}
                };

                if (!onError) {
                    // dispatchNotification({
                    //     url: URL,
                    //     status: error,
                    //     requestTitle: requestTitle,
                    //     responseContent: responseContent,
                    // });
                } else {
                    onError(handlerError)
                }
            });
        })

}