import React from 'react'
import Button from "../../../components/ripple/Button";
import styles from './ScrollTopButton.module.scss'

class ScrollTopButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            reveal: false
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollButtonDisplay)
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollButtonDisplay)
    };

    scrollButtonDisplay = () => {
        let h = document.documentElement,
            b = document.body,
            st = 'scrollTop',
            sh = 'scrollHeight';
        let scrollPercent = (h[st] || b[st]) / ((h[sh] || b[sh]) - (h.clientHeight || b.clientHeight)) * 100;

        this.setState({reveal: scrollPercent > 15});
    };

    scrollToTop = () => {
        window.scrollTo({top: document.body.getBoundingClientRect().top, behavior: "smooth"})
    };

    render() {

        if (!!this.props.hide) return null;

        let {reveal} = this.state;
        let {lift} = this.props;
        let show = reveal ? ` ${styles['button-scroll-top--visible']}` : ` ${styles['button-scroll-top--hidden']}`;
        let higher = !!lift ? ` ${styles['button-scroll-top--higher']}` : '';

        return (
            <Button
                className={`${styles['button-scroll-top']}${show}${higher}`}
                onClick={this.scrollToTop}
                aria-label="Scroll to top"
                icon='fas fa-chevron-up'
                importedIcon
                visuals={{
                    colorBasic: ['var(--colorAccentLight)'],
                    height: '6.5rem',
                    borderRadius: '50%',
                }}
            />
        )
    }
}

export default ScrollTopButton